import { useContext, useEffect, useState } from 'react';
import { axiosClient } from '../../App';
import { accStateToRoute } from '../Auth.api';
import { AccountStates } from '../Auth.constant';
import { AuthContext } from '../Auth.context';
// import Jwt from 'jwt-decode';
import { TokenState } from './UseInitToken.hook';

const { TOKEN_STATE_VALID } = TokenState

/**
 * @author Hermann
 * @description Sert a décrypter le token et le mettre en forme
 * @param {void} callback 
 */
const useAccount = ( callback ) => {
    const { tokenData, tokenState, destroy, setAuthToken, setRefreshToken, authToken, refresh } = useContext(AuthContext);
    const [ isConnected, setIsConnected ] = useState(tokenState === TOKEN_STATE_VALID);
    const [ isSetup ] = useState( tokenData && tokenData.account_state === AccountStates.ACCOUNT_ACTIVATED)
    //const [ isSetup ] = useState( tokenData && (tokenData.account_state).includes("SIGNUP_"))
    const [ setupRoute, setSetupRoute ] = useState(tokenData && accStateToRoute(tokenData.account_state));

    useEffect(() => {
        setIsConnected(tokenState === TOKEN_STATE_VALID)
    }, [ tokenState, isConnected ])

    useEffect(() => {
        if (isConnected)
            axiosClient.defaults.headers.Authorization = `Bearer ${authToken}`;
    }, [isConnected, authToken])

    useEffect(() => {
        if (isConnected) setSetupRoute(accStateToRoute(tokenData.account_state))
    }, [ isConnected, isSetup, tokenData]);

    useEffect(() => {
        if (callback)
            if (isConnected && tokenData)
                callback({ isConnected,tokenData });
            else callback({ isConnected })
    }, [ callback, tokenData, isConnected ])

    return {
        accountData: tokenData,
        setupRoute,
        authToken,
        isConnected,
        refreshAccount: refresh,
        isSetup,
        connect: ( authToken, refreshToken ) => { setAuthToken(authToken); setRefreshToken(refreshToken) },
        disconnect : destroy
    };
}

export default useAccount;
const createMessage = (name, type, payload) => (pl = payload) => ({
  name: name || 'NO_ARG',
  type: type || 'ERROR',
  payload: pl || payload,
});

export const TOKEN_VALID = createMessage('TOKEN_VALID', 'SUCCESS', null);
export const TOKEN_EXPIRED = createMessage('TOKEN_EXPIRED', 'WARNING', null);
export const TOKEN_NOT_FOUND = createMessage('TOKEN_NOT_FOUND', 'ERROR', null);

export const SIGNUP_SOCIAL_WAITING_IDENTITY_VALIDATION = 'SIGNUP_SOCIAL_WAITING_IDENTITY_VALIDATION';
export const SIGNUP_WAITING_EMAIL_VALIDATION = 'SIGNUP_WAITING_EMAIL_VALIDATION';
export const SIGNUP_WAITING_CGU_VALIDATION = 'SIGNUP_WAITING_CGU_VALIDATION';
export const SIGNUP_WAITING_STEP_PACK = 'SIGNUP_WAITING_STEP_PACK';
export const SIGNUP_WAITING_STEP_PAYMENT = 'SIGNUP_WAITING_STEP_PAYMENT';
export const SIGNUP_WAITING_STEP_CF = 'SIGNUP_WAITING_STEP_CF';
export const SIGNUP_WAITING_STEP_PARTNER_LOGIN = 'SIGNUP_WAITING_STEP_PARTNER_LOGIN';
export const SIGNUP_WAITING_STEP_IMPORT_PASSWORD = 'SIGNUP_WAITING_STEP_IMPORT_PASSWORD';
export const NEW_EMAIL_WAITING_VALIDATION = 'NEW_EMAIL_WAITING_VALIDATION';

export const AccountStates = {
  SIGNUP_SOCIAL_WAITING_IDENTITY_VALIDATION: 'SIGNUP_SOCIAL_WAITING_IDENTITY_VALIDATION',
  SIGNUP_WAITING_EMAIL_VALIDATION: 'SIGNUP_WAITING_EMAIL_VALIDATION',
  SIGNUP_WAITING_CGU_VALIDATION: 'SIGNUP_WAITING_CGU_VALIDATION',
  SIGNUP_WAITING_STEP_PACK: 'SIGNUP_WAITING_STEP_PACK',
  SIGNUP_WAITING_STEP_PAYMENT: 'SIGNUP_WAITING_STEP_PAYMENT',
  SIGNUP_WAITING_STEP_CF: 'SIGNUP_WAITING_STEP_CF',
  SIGNUP_WAITING_STEP_PARTNER_LOGIN: 'SIGNUP_WAITING_STEP_PARTNER_LOGIN',
  SIGNUP_WAITING_STEP_IMPORT_PASSWORD: 'SIGNUP_WAITING_STEP_IMPORT_PASSWORD',
  NEW_EMAIL_WAITING_VALIDATION :'NEW_EMAIL_WAITING_VALIDATION',
  ACCOUNT_BANNED: 'ACCOUNT_BANNED',
  ACCOUNT_ACTIVATED: 'ACCOUNT_ACTIVATED',
  ACCOUNT_CANCELED: 'ACCOUNT_CANCELED',
  ACCOUNT_DEACTIVATED: 'ACCOUNT_DEACTIVATED',
  ACCOUNT_DELETED: 'ACCOUNT_DELETED'
}

export const AccountTypes = {
  ADL: "ADL",
  SOCIAL: "SOCIAL"
}

export const ACCOUNT_BANNED = 'ACCOUNT_BANNED';
export const ACCOUNT_ACTIVATED = 'ACCOUNT_ACTIVATED';
export const ACCOUNT_CANCELED = 'ACCOUNT_CANCELED';

import { axiosClient } from "../App";
import { USERS_API_URL, ACTIVATION_CODE_API_URL, VAULT_API_URL, LOGO_API_URL, AUTOMATE_API_URL, STRIPE_API_URL } from "../App.config";

export async function getAllUserPaymentCards(tokenData) {
    return axiosClient
        .get(`${USERS_API_URL}/${tokenData.id}/cards`)
        .then(({ data }) => data);
}

export async function getIdentity(tokenData) {
    return axiosClient
        .get(`${USERS_API_URL}/${tokenData.id}/identity`)
        .then(({ data }) => data);
}

export async function patchIdentity(tokenData, {
    nom,
    prenom,
    username,
    email,
    date_naissance,
    numero_telephone,
    gender,
    address,
    zipCode,
    city,
    country
}) {
    // Ici cette vérification doit être la même coté back
    const data = {
        nom: nom.toUpperCase(),
        prenom:
            prenom.toLowerCase().charAt(0).toUpperCase() +
            prenom.toLowerCase().slice(1),
        username: username !== null ? (
            username.toLowerCase().charAt(0).toUpperCase() +
            username.toLowerCase().slice(1)) : null,
        email: email.toLowerCase(),
        date_naissance,
        numero_telephone,
        gender,
        address,
        zipCode,
        city,
        country
    };

    // Récupération de l'id
    return axiosClient.patch(`${USERS_API_URL}/${tokenData.id}/identity`, data)
        .then((res) => res.data)
}

// Identité simplifiée de la personne
export async function inscriptionGetIdentity(accountData) {
    // Récupération de l'id
    return axiosClient
        .get(`${USERS_API_URL}/${accountData.id}/identity`)
        .then((res) => res);
}

export async function getListSafeVault({ pin }) {
    // Récupération de l'id
    const pinCode = {
        pin,
    };
    return axiosClient.post(`${VAULT_API_URL}/`, pinCode).then(({ data }) => {
        const vault_list = data;
        return vault_list;
    });
}

export async function getCredentialsSafeVault(service, pinCode) {
    // Récupération de l'id
    const data = {
        service,
        pinCode,
    };
    return axiosClient.post(`${VAULT_API_URL}/credentials`, data).then(({ data }) => {
        const credentials = data;
        return credentials;
    });
}

export async function testCredentials(service) {
    return axiosClient.post(`${AUTOMATE_API_URL}/connexion`, service).then(({ data }) => {
        const status = data;
        //console.log(status);
        return status;
    });
}

export async function handleActivationService(service) {
    return axiosClient.post(`${AUTOMATE_API_URL}/handleServiceAction`, service).then(({ data }) => {
        const status = data;
        //console.log(status);
        return status;
    });
}

export async function getActivationCode(serviceCode) {
    return axiosClient.post(`${ACTIVATION_CODE_API_URL}/filters/service`, serviceCode).then(({ data }) => {
        const status = data;
        //console.log(status);
        return status;
    });
}


export async function updateSafeVault({ service, login, password, deletable }) {
    let data = {
        service_name: service,
        login: login,
        password: password,
        deletable: deletable
    };
    return axiosClient.post(VAULT_API_URL + "/update", data).then((response) => response);
}

export async function validatePin({ pin }) {
    let data = {
        pin: pin
    };
    return axiosClient.post(USERS_API_URL + "/pin", data).then((response) => response);
}

export async function updatePin({ pin }) {
    let data = {
        pin: pin
    };
    return axiosClient.patch(USERS_API_URL + "/pin", data).then((response) => response);
}

/**
 * Acceptation des CGU
 * @returns {Promise<*>}
 */
export async function acceptCGU(tokenData) {
    return axiosClient.patch(USERS_API_URL + `/${tokenData.id}/cgu`, {}).then((response) => response);
}

export async function deleteSafeVault(tokenData,service, pinCode) {
    let data = {
        service_name: service,
        pinCode: pinCode
    };
    return axiosClient.post(VAULT_API_URL + `/${tokenData.id}/delete`, data).then((response) => response);
}

/* export async function getDeletable(service, pinCode) {
    // Récupération de l'id
    const token = window.localStorage.getItem("authToken");
    if (token) {
        let data = {
            service: service,
            pinCode: pinCode
        };
        return axiosClient.post(VAULT_API + `/deletable`, data).then(({data}) => {
            let deletable = data;
            return deletable;
        });
    }
} */

export async function getPendingOrder(accountData) {
    // Récupération de l'id
    return axiosClient
        .get(USERS_API_URL + `/${accountData.id}/pending-order`).then(({ data }) => {
            let pending_order = data;
            return pending_order;
        });
}

export async function getPendingMigrateOrder(accountData) {
    // Récupération de l'id
    return axiosClient
        .get(USERS_API_URL + `/${accountData.id}/pending-order-migrate`).then(({ data }) => {
            let pending_order = data;
            return pending_order;
        });
}

export async function launchMigration() {
    // Récupération de l'id
    return axiosClient
        .get(USERS_API_URL + `/launch-migrate`).then((response) => response);
}

// TODO : à modifie, stocker url du partenaire / service
export async function getLogo(service) {
    const ext = ['.fr', '.com', '.re'];
    const serviceWithoutSpace = service.split(" ")[0];
    let logo_exist = false;
    let url = '';
    if (logo_exist === false) {
        for (const element of ext) {
            //console.log(element)
            const url_suffix = serviceWithoutSpace + element;
            //console.log(url_suffix)
            url = LOGO_API_URL + url_suffix;
            /* const response = await fetch(url);
                  if(response.status === 200){
                      logo_exist = true;
                      break;
                  }
                  else {
                      logo_exist = false;
                  } */
            try {
                const response = await fetch(url);
                if (response.status === 200) {
                    logo_exist = true;
                    break;
                }
            } catch (e) {
                logo_exist = false;
            }
        }
    }

    if (logo_exist === true) {
        return `${url}`;
    }
    return '/src/assets/img/ADL_black.svg';
}

export async function inscriptionPatchIdentity({ id }, { nom, prenom, email, gender, date_naissance, parrain, address, zipCode, city, country, numero_telephone }) {
    const data = {
        nom: nom.toUpperCase(),
        prenom:
            prenom.toLowerCase().charAt(0).toUpperCase()
            + prenom.toLowerCase().slice(1),
        email: email.toLowerCase(),
        date_naissance: date_naissance,
        gender: gender,
        parrain: parrain,
        address: address,
        zipCode: zipCode,
        city: city,
        country: country,
        numero_telephone: numero_telephone
    };
    //console.log(data);

    // Récupération de l'id
    return axiosClient
        .patch(`${USERS_API_URL}/${id}/identity`, data)
        .then((response) => response);
}

export async function patchModifyPassword(tokenData, { oldpassword, newpassword, newretypedpassword }) {
    // Récupération de l'id
    const data = {
        oldPassword: oldpassword,
        newPassword: newpassword,
        newRetypedPassword: newretypedpassword,
    };
    return axiosClient
        .patch(`${USERS_API_URL}/${tokenData.id}/password`, data)
        .then((response) => response);
}

export async function patchUpdateProfilePic(tokenData, photo) {
    return axiosClient
        .patch(`${USERS_API_URL}/${tokenData.id}/profilePic`, photo)
        .then((response) => response);
}


export async function getListInvoices(tokenData) {
    return axiosClient
        .get(`${USERS_API_URL}/${tokenData.id}/invoices`)
        .then(({ data }) => data);
}

export async function getInvoice(tokenData, order) {
    return axiosClient(`${USERS_API_URL}/${tokenData.id}/invoices/${order}`, {
        method: 'GET',
        responseType: 'blob',
    }).then((response) => response.data);
}

export async function getUserPack(tokenData) {
    //console.log(`Route appelée : ${USERS_API_URL}/${tokenData.id}/packs`)
    return axiosClient
        .get(`${USERS_API_URL}/${tokenData.id}/packs`)
        .then(({ data }) => data);
}

export async function deleteOneUserPaymentCard(tokenData, cardId) {
    return axiosClient
        .delete(`${USERS_API_URL}/${tokenData.id}/cards/${cardId}`)
        .then(({ data }) => data);
}

export async function setPaymentCardAsDefault(tokenData, cardId) {
    return axiosClient
        .patch(`${USERS_API_URL}/${tokenData.id}/cards/${cardId}`)
        .then(({ data }) => data);
}

export async function createNewUserPaymentCard(tokenData) {
    return axiosClient
        .post(`${USERS_API_URL}/${tokenData.id}/cards`, tokenData)
        .then(({ data }) => data);
}

export async function resilierAbonnement(tokenData) {
    return axiosClient
        .delete(`${USERS_API_URL}/${tokenData.id}/packs`)
        .then(({ data }) => data);
}

export async function reprendreAbonnement(tokenData) {
    return axiosClient
        .patch(`${USERS_API_URL}/${tokenData.id}/packs/continue`)
        .then(({ data }) => data);
}

export async function upgradeCustomPack(tokenData, request) {
    //console.log("Route appelé: "+`${USERS_API_URL}/${tokenData.id}/packs/custom`);
    //console.log(request);
    return axiosClient
        .patch(`${USERS_API_URL}/${tokenData.id}/packs/custom`, request)
        .then(({ data }) => data);
}

export async function cancelCustomChange(tokenData, code) {
    return axiosClient
        .delete(`${USERS_API_URL}/${tokenData.id}/packs/custom/${code}`)
        .then(({ data }) => data);
}

export async function changePack(tokenData, request) {
    return axiosClient
        .patch(`${USERS_API_URL}/${tokenData.id}/packs/new`, request)
        .then(({ data }) => data);
}

export async function annulerEvolutionPack(tokenData) {
    return axiosClient
        .delete(`${USERS_API_URL}/${tokenData.id}/packs/new`)
        .then(({ data }) => data);
}

export async function getFilleuls(tokenData) {
    return axiosClient
        .get(`${USERS_API_URL}/${tokenData.id}/filleuls`)
        .then(({ data }) => data);
}

export async function sendParrainageLink(tokenData, email) {
    return axiosClient
        .post(`${USERS_API_URL}/${tokenData.id}/filleuls`, { email: email })
        .then(({ data }) => data);
}

// export async function setupPackConfirm(tokenData, pack) {
//     return axiosClient.post(`${USERS_API_URL}/${tokenData.id}/packs`, pack.id > -1 ? { pack: pack.id } : {
//         pack: pack.id,
//         selectedServices: pack.selectedServices.map((p) => ({ code: p.code }))
//     }).then((data) => {
//         if (data.status === 201 || data.statusText === 'Created') return true;
//         return false;
//     });
// }
export async function setupPackConfirm(tokenData, pack) {
    return axiosClient
        .post(`${USERS_API_URL}/${tokenData.id}/packs`, pack)
        .then((data) => {
            if (data.status === 201 || data.statusText === 'Created') return true;
            return false;
        }).catch((error)=> {
            console.log(error);
        });
}

export async function goBackPack(tokenData) {
    return axiosClient
        .post(`${USERS_API_URL}/${tokenData.id}/gobackpack`)
        .then((data) => {data});
}


export async function createUserPack(tokenData, pack) {
    return axiosClient.post(`${USERS_API_URL}/${tokenData.id}/packs`, pack).then((data) => {
        if (data.status === 201 || data.statusText === 'Created') return true;
        return false;
    });
}
//get zip code
export async function getZipCode(query) {
    return fetch('https://api-adresse.data.gouv.fr/search/?q=' + query + '&type=municipality').then((data) => data.json());
}

export async function payPendingOrder(tokenData) {
    return axiosClient
        .post(`${USERS_API_URL}/${tokenData.id}/pending-order/pay`)
        .then(({ data }) => data);
}

export async function cancelPendingOrder(tokenData) {
    return axiosClient
        .delete(`${USERS_API_URL}/${tokenData.id}/pending-order/pay`)
        .then(({ data }) => data);
}

export async function getUserPackTimeLine(tokenData, request, simulateImmediatePayment) {
    return axiosClient
        .post(`${USERS_API_URL}/${tokenData.id}/packs/timeline${simulateImmediatePayment ? '/pay' : ''}`, request)
        .then((data) => data.data);
}

export async function getUserPackTimeLineV2(tokenData, request, simulateImmediatePayment) {
    return axiosClient
        .post(`${USERS_API_URL}/${tokenData.id}/packs/timelinev2${simulateImmediatePayment ? '/pay' : ''}`, request)
        .then((data) => data.data);
}

const UserApi = {
    inscriptionGetIdentity,
    inscriptionPatchIdentity,
    patchIdentity,
    getPendingOrder,
    getListSafeVault,
    updateSafeVault,
    deleteSafeVault,
    getLogo,
    patchModifyPassword,
    patchUpdateProfilePic,
    getListInvoices,
    getInvoice,
    getCredentialsSafeVault,
    testCredentials,
    validatePin,
    updatePin,
    acceptCGU,
    goBackPack,
    getAllUserPaymentCards,
    getUserPack,
    createNewUserPaymentCard,
    deleteOneUserPaymentCard,
    setPaymentCardAsDefault,
    resilierAbonnement,
    upgradeCustomPack,
    getActivationCode,
    reprendreAbonnement,
    handleActivationService,
    changePack,
    annulerEvolutionPack,
    cancelCustomChange,
    getFilleuls,
    sendParrainageLink,
    setupPackConfirm,
    createUserPack, // Eventuellement garder celui la et supprimer setupPackConfirm
    getZipCode,
    payPendingOrder,
    cancelPendingOrder,
    getUserPackTimeLine,
    getUserPackTimeLineV2,
    getPendingMigrateOrder,
    launchMigration
};

export default UserApi;

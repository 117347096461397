import React from 'react';
import { render } from 'react-dom';
import App from './modules/App';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.css';
import withClearCache from "./ClearCache";
//import {getBuildDate} from "./utils/utils";

// Analytics
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-135716247-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const rootNode = document.getElementById('root');
const ClearCacheComponent = withClearCache(MainApp);

function MainApp() {
    return (
        <React.StrictMode>
            <App />
        </React.StrictMode>
    )
}

render(
    <ClearCacheComponent />, rootNode
)

// J'ai rajouter StrictMode pour faire vérification comme:
/**
 * Identifier les composants utilisant des méthodes de cycle de vie dépréciées
 * Signaler l’utilisation dépréciée de l’API ref à base de chaîne de caractères
 * Signaler l’utilisation dépréciée de findDOMNode
 * Détecter des effets de bord inattendus
 * Détecter l’API dépréciée de Contexte (ex. childContextTypes)
 */
// render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   rootNode,
// );


/*serviceWorkerRegistration.register({
    onUpdate: (registration) => console.log(registration),
    onSuccess: (registration) => console.log(registration),
});*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

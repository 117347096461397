// #region Global
import { lazy, Suspense } from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';
// #endregion
//#region Routers
const MainRouter = lazy(() => import('./Main/Main.router'));
const MainRouterV2 = lazy(() => import('./Main/MainV2.router'));
const AuthRouter = lazy(() => import('./Auth/Auth.router'));
const SetupRouter = lazy(() => import('./Setup/Setup.router'));
const UserRouter = lazy(() => import('./User/User.router'));
//#endregion
// import Chatbox from './Main/Components/Chatbox';
// import { useEffect } from 'react';
import { useLoading, Bars } from '@agney/react-loading';
import ReactGA from 'react-ga';
import { useEffect  } from 'react';


export default function AppRouter() {
  const TRACKING_ID = "UA-135716247-1"; // YOUR_OWN_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    loaderProps: {
      // Any props here would be spread on to the indicator element.
      style: { margin: 'auto auto', color: '#1F2937', alignItems: 'center', justifyContent: 'center', height: '100%' }
    },
    indicator: <Bars width="100" />
  });
  return (
    <>
      <Switch>
        <Route exact path="/"><Suspense {...containerProps} fallback={indicatorEl}><MainRouter /></Suspense></Route>
        <Route exact path="/V2-XYZ"><Suspense {...containerProps} fallback={indicatorEl}><MainRouterV2 /></Suspense></Route>
        <Route path="/auth"><Suspense {...containerProps} fallback={indicatorEl}><AuthRouter /></Suspense></Route>
        <Route path="/setup"><Suspense {...containerProps} fallback={indicatorEl}><SetupRouter /></Suspense></Route>
        <Route path="/user"><Suspense {...containerProps} fallback={indicatorEl}><UserRouter /></Suspense></Route>

      </Switch>
      {/* <Chatbox/> */}
    </>
  );
}

import {
    createContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types'
import {getIdentity} from './User.api';
import useAccount from '../Auth/hooks/UseAccount.hook';
import Image from '../../assets/img/user-placeholder.jpg'

const InitialState = {
    invoices: null,
    cards: null,
    accountIdentity: {
        prenom: '',
        nom: '',
        email: '',
        date_naissance: '',
        username: '',
        numero_telephone: '',
        photo: '',
        gender: '',
        address:'',
        zipCode:'',
        city:'',
        country:''
    }
};
const UserContext = createContext(InitialState);

const UserContextProvider = ({children}) => {
    const {accountData, isConnected} = useAccount();
    const [accountIdentity, setAccountIdentity] = useState(InitialState.accountIdentity);
    const [listPassword, setListPassword] = useState([]);
    const [cards, setListCards] = useState(InitialState.cards);
    const [invoices, setListInvoices] = useState(InitialState.invoices);
    const [userPack, setUserPack] = useState(null);
    const [currentUserpack, setCurrentUserPack] = useState(null);
    const [newUserPack, setNewUserPack] = useState(null);
    const [actualUserPack, setActualUserPack] = useState({
        exist: false,
        libelle: null,
        type: null,
        description: null,
        prix: null,
        services: null,
        prix_public: null
    });
    const [entry, setEntry] = useState({
        service: '',
        login: '',
        password: '',
        need_master: false,
        auto_login: false,
        new: false,
    });
    // User Identity
    useEffect(() => {
        const fetchIdentity = async () => {
            try {
                let identity = await getIdentity(accountData);
                if (identity.photo === null) {
                    identity.photo = Image;
                }
                setAccountIdentity(identity);
            } catch (err) {

                console.error(err);
            }
        };
        if (isConnected) {
            fetchIdentity();
        }
    }, [accountData, isConnected]);


    return (
        <UserContext.Provider value={{
            accountIdentity, setAccountIdentity,
            entry, setEntry,
            listPassword, setListPassword,
            userPack, setUserPack,
            actualUserPack, setActualUserPack,
            newUserPack, setNewUserPack,
            currentUserpack, setCurrentUserPack,
            cards, setListCards,
            invoices, setListInvoices
        }}> {children} </UserContext.Provider>
    );
};

UserContextProvider.propTypes = {
    children: PropTypes.any
}

export {UserContextProvider, UserContext};

import { axiosClient } from "../App";


// #region Constants
import { useRequest } from '../../libs/Hooks/UseRequest';
import {
    LOGIN_API,
    FORGOT_API,
    SIGNUP_API,
    // NEW_TOKEN,
    TOKEN_API,
    RESEND_ACTIVATION_EMAIL_API,
    RESET_PASSWORD_API,
    CHECK_TOKEN_VALIDITY,
    CHECK_SEUIL,
    UNSUSCRIBE
} from '../App.config';
import {
    SIGNUP_SOCIAL_WAITING_IDENTITY_VALIDATION,
    SIGNUP_WAITING_EMAIL_VALIDATION,
    SIGNUP_WAITING_STEP_IMPORT_PASSWORD,
    SIGNUP_WAITING_STEP_CF,
    SIGNUP_WAITING_STEP_PARTNER_LOGIN,
    SIGNUP_WAITING_STEP_PACK,
    SIGNUP_WAITING_STEP_PAYMENT,
    SIGNUP_WAITING_CGU_VALIDATION,
} from './Auth.constant';
// #endregion

export const useRegister = () => {
    return useRequest(data => ({
        url: SIGNUP_API,
        method: "post",
        data
    }))
}

export const useForgotPassword = () => {
    return useRequest(data => ({
        url: FORGOT_API,
        method: "post",
        data
    }))
}

export const useResetPassword = () => {
    return useRequest(data => ({
        url: RESET_PASSWORD_API,
        method: "post",
        data
    }))
}
//TODO: Before using this hook, ensure to put email in lowerCase
export const useResendActivationEmail = () => {
    return useRequest(data => ({
        url: RESEND_ACTIVATION_EMAIL_API,
        method: "post",
        data
    }));
}

export const useLogin = () => {
    return useRequest(data => ({
        url: LOGIN_API,
        method: "post",
        data
    }))
};

export const useAuthenticateToken = () => {
    return useRequest(data => ({
        url: TOKEN_API,
        method: "post",
        data
    }))
}

export const useCheckToken = () => {
    return useRequest(data => ({
        url: CHECK_TOKEN_VALIDITY,
        method: "post",
        data
    }));
}

export async function checkSeuil(code) {
    return axiosClient
        .get(`${CHECK_SEUIL}/${code}`)
        .then(({ data }) => data);
}

export async function unsuscribe(code) {
    return axiosClient
        .get(`${UNSUSCRIBE}/${code}`)
        .then(({ data }) => data);
}


/**
 * Transform AccountState en Route
 * @param {*} accState
 * @returns String
 * @description Return une route par rapport à la variable passé dans le switch {AccountState}
 */
export const accStateToRoute = (accState) => {
    switch (accState) {
        case SIGNUP_WAITING_CGU_VALIDATION:
            return '/setup/srvc-cgu';
        case SIGNUP_WAITING_EMAIL_VALIDATION:
            return '/setup/srvc-email';
        case SIGNUP_WAITING_STEP_IMPORT_PASSWORD:
            return '/setup/srvc-import_data';
        case SIGNUP_WAITING_STEP_PACK:
            return '/setup/srvc-signup';
        case SIGNUP_SOCIAL_WAITING_IDENTITY_VALIDATION:
            return '/setup/srvc-identity';
        case SIGNUP_WAITING_STEP_PAYMENT:
            return '/setup/srvc-payment';
        case SIGNUP_WAITING_STEP_CF:
            return '/setup/srvc-cf';
        case SIGNUP_WAITING_STEP_PARTNER_LOGIN:
            return '/setup/srvc-partner';
        default:
            return '/';
    }
}
// #region External Components
import { createContext } from 'react';
import PropTypes from 'prop-types'
import useInitToken from './hooks/UseInitToken.hook';
// #endregion

const AuthContext = createContext();


const AuthContextProvider = ({ children }) => {
  const { 
    authToken, 
    setAuthToken, 
    tokenState, 
    refreshToken, 
    setRefreshToken,
    tokenData, 
    destroy,
    refresh
  } = useInitToken();
  
  return (
    <AuthContext.Provider value={{
      authToken,
      tokenState,
      tokenData,
      setAuthToken,
      refreshToken,
      setRefreshToken,
      destroy,
      refresh
    }}> {children} </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.any
}

export { AuthContextProvider, AuthContext };

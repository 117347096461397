import {
    createContext, useState
} from 'react';
import PropTypes from 'prop-types';
import useAccount from '../Auth/hooks/UseAccount.hook';


const InitialState = {
    setupMenu: [
         {
            nom_menu: "Mon abonnement",
            description: "Sélectionnez les services que vous voulez payer moins cher et regrouper les dans un pack.",
            to: "/setup/srvc-signup",
            trait: "trait-debut",
            show: true,
            done: false,
            isActive: false,
            niveau: 1,
            etape: "packs",
            accountState: 'SIGNUP_WAITING_STEP_PACK'
        }, {
            nom_menu: "Mon identité",
            description: "Validez vos informations de facturation.",
            to: "/setup/srvc-identity",
            trait: "trait-debut",
            show: true,
            done: false,
            isActive: false,
            niveau: 2,
            etape: "identity",
            accountState: 'SIGNUP_SOCIAL_WAITING_IDENTITY_VALIDATION'
        },{
            nom_menu: "Mon paiement",
            description : "Procéder à l'enregistrement de votre carte bleu pour vous abonner.",
            to: "/setup/srvc-payment",
            trait: "trait-debut",
            show: true,
            done: false,
            isActive: false,
            niveau: 3,
            etape: "payment",
            accountState: 'SIGNUP_WAITING_STEP_PAYMENT'
        }, 
        {
            nom_menu: "Mes comptes",
            description : "Dîtes nous si vous avez déjà des comptes pour les services souscrits et configurez votre coffre fort.",
            to: "/setup/srvc-partner",
            trait: "trait-fin",
            show: true,
            done: false,
            isActive: false,
            niveau: 4,
            etape: "partner",
            accountState: 'SIGNUP_WAITING_STEP_PARTNER_LOGIN'
        }
    ],
    setupNiveau: {
        SIGNUP_WAITING_STEP_PACK: 1,
        SIGNUP_SOCIAL_WAITING_IDENTITY_VALIDATION: 2,
        SIGNUP_WAITING_STEP_PAYMENT: 3,
        SIGNUP_WAITING_STEP_PARTNER_LOGIN: 4,
    },

    niveauRoute: {
        '/setup/srvc-signup': 1,
        '/setup/srvc-identity': 2,
        '/setup/srvc-payment': 3,
        '/setup/srvc-partner': 4,
    },
};

const SetupContext = createContext(InitialState);

const SetupContextProvider = ({children}) => {
    // #region Initialise state
    const [setupMenu] = useState(InitialState.setupMenu);
    const [setupNiveau] = useState(InitialState.setupNiveau);
    const {accountData, isConnected, isSetup} = useAccount();
    // #endregion


    const stepIsDone = (stateA) => {
        if (isConnected) {
            if (isSetup)
                return true;
            else return stateA < setupNiveau[accountData.account_state];
        } else return false
    };

    return (
        <SetupContext.Provider value={{
            setupMenu,
            setupNiveau,
            stepIsDone
        }}
        >{children}</SetupContext.Provider>
    );
};

SetupContextProvider.propTypes = {
    children: PropTypes.any
}

export {SetupContextProvider, SetupContext};

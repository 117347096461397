// #region External Components
import {ToastContainer, toast} from 'react-toastify';
import * as crypto from 'crypto-js';
// #endregion
// #region Router & Context & CSS
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter as RouterContextProvider} from 'react-router-dom';
import AppRouter from './App.router';
import {AuthContextProvider} from './Auth/Auth.context';
import {AppContextProvider} from './App.context';
import {SetupContextProvider} from './Setup/Setup.context';
import {UserContextProvider} from './User/User.context';
import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import {getLocalItem} from '../libs/useLocalState';
import responseMessage from "../components/ResponseMessage/ResponseMessage";
import { Helmet } from "react-helmet";
// #endregion

//cache setup
const cache = setupCache({
    maxAge: 15 * 60 * 1000
  })

let instance = axios.create({
    headers: {
        'Authorization': `Bearer ${getLocalItem("authToken")}`,
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
    },
    adapter: cache.adapter
});

instance.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${getLocalItem("authToken")}`;

    // Cryptage de l'envoi
    //config.data = {data: crypto.AES.encrypt(JSON.stringify(config.data), '@McQfTjWnZr4u7x!A%D*F-JaNdRgUkXp').toString()};
    return config;
});

instance.interceptors.response.use((response) => {
    // Décryptage de l'envoi
    //var bytes = crypto.AES.decrypt(response.data, '@McQfTjWnZr4u7x!A%D*F-JaNdRgUkXp');
    //var originalText = bytes.toString(crypto.enc.Utf8);

    // Réaffectation de la réponse dcrypté dans le data de la response
    //console.log(response);
    //response.data = JSON.parse(originalText)
    return response
});

export const axiosClient = instance;

const App = () => (

    <RouterContextProvider>
        <Helmet>
            <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
        </Helmet>
        <AppContextProvider>
            <AuthContextProvider>
                <UserContextProvider>
                    <SetupContextProvider>
                        <AppRouter/>
                        <ToastContainer
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            position={toast.POSITION.BOTTOM_CENTER}
                            limit={1}
                        />
                    </SetupContextProvider>
                </UserContextProvider>
            </AuthContextProvider>
        </AppContextProvider>
    </RouterContextProvider>
);

export default App;

import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types'


export const ScreenConstants = {
  Small: 640,
  Medium: 768,
  Large: 1024,
  ExtraLarge: 1280,
};

/**
 * @description Retourne une clé de ScreenConstants. Depend de la largeur de l'écran.
 * Le paramétre w est là au cas où depuis l'endroit vous appelé vous ne pouvez pas accéder à window.innerWith
 * @param {number} w Width
 */
export const getScreenState = (w) => {
  const width = w || window.innerWidth;
  const screenValues = Object.values(ScreenConstants);
  const result = screenValues.indexOf(
    screenValues.filter((v, i) => (
      width > v && width < screenValues[i + 1])
            || (width > v && v === screenValues[3])
            || (width > 0 && width < v && v === screenValues[0]))[0],
  );
  return Object.keys(ScreenConstants)[result];
};

const InitialState = {
  screenState: getScreenState(),
};

const AppContext = createContext(InitialState);

const AppContextProvider = ({ children }) => {
  const [screenState, setScreenState] = useState(InitialState.screenState);
  const [ tooltipStatus, setTooltipStatus ] = useState(0);

  useEffect(() => {
    const updateScreenState = () => {
      const newScreenState = getScreenState();
      if (screenState !== newScreenState) setScreenState(newScreenState);
    };
    window.addEventListener('resize', updateScreenState);
    return () => window.removeEventListener('resize', updateScreenState);
  }, [screenState]);

  return (
    <AppContext.Provider value={{
      screenState,
      tooltipStatus,
      setTooltipStatus
    }}
    >{children}</AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.any
}

export { AppContextProvider, AppContext };
